<template>
  <div class="body container">
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('message.notice')"
      :visible.sync="isAlreadyPaidDialogVisible"
      width="600px"
    >
      <strong>請問該筆帳單已經全額付款完成了嗎？</strong>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitUnpaidAmountDialog = true">
          還沒，還需驗證其他付款紀錄
        </el-button>
        <el-button
          type="primary"
          @click="
            () => {
              markPaid();
              isAlreadyPaidDialogVisible = false;
            }
          "
        >
          帳單已經付款完成了！
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="$t('message.notice')"
      :visible.sync="submitUnpaidAmountDialog"
      width="600px"
    >
      <el-form>
        <strong>
          {{
            `此帳單應付金額為 ${addCurrencySymbol(
              $store.getters["bill/billTotalPrice"],
              $store.getters["bill/billCurrency"]
            )}，目前已付金額為 ${addCurrencySymbol(
              allPaidPrice,
              $store.getters["bill/billCurrency"]
            )}`
          }}
        </strong>
        <hr />
        <el-form-item label="請問該筆帳單還剩多少金額未付？">
          <InputMoney
            :money="$store.getters['bill/billDifferencePrice']"
            :currency="$store.getters['bill/billDifferencePriceCurrency']"
            @changeMoney="
              price => {
                $store.commit('bill/setBillDifferencePrice', price);
              }
            "
            @changeCurrency="
              currency => {
                $store.commit('bill/setBillDifferencePriceCurrency', currency);
              }
            "
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submitUnpaidAmountDialog = false">
          回上一步
        </el-button>
        <el-button type="primary" @click="updateDifferencePrice">
          儲存
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('bill.sendMail.title')"
      :visible.sync="sendMailDialogVisible"
      width="800px"
    >
      <SendMail
        :students="$store.getters['bill/students']"
        :tutoringPayments="tutoringPayments"
        :parentId="$store.getters['bill/billParent'].id"
        sendButtonText="重寄帳單"
        :onSendMail="sendMail"
      />
    </el-dialog>
    <Breadcrumb>
      <template slot="lastPage">
        {{ pageTitle }}
      </template>
    </Breadcrumb>
    <template v-if="isRoleAdmin()">
      <el-alert
        v-if="
          $store.getters['bill/billStatus'] === EnumBillStatuses.created &&
            hasSomeTransactionUnchecked
        "
        show-icon
        type="error"
      >
        <template slot="title">
          <div v-if="hasSomeTransactionUnchecked">
            {{ `帳單未付款項還剩約 ${this.remainingCost}，請確認付款紀錄！` }}
          </div>
        </template>
      </el-alert>
      <el-alert v-if="!isStudentAtThisBill && !isPaid" show-icon type="warning">
        <template slot="title">
          <span>
            {{
              `${$route.query.student} 未在此次同家長（${$store.getters["bill/billParent"].name}）所成立的帳單中，如果需要可以點擊`
            }}
            <router-link
              :to="{
                name: 'createBillWithOtherBill',
                params: { billId: $route.params.billId }
              }"
            >
              編輯
            </router-link>
            重新建立帳單
          </span>
        </template>
      </el-alert>
      <template v-if="isPaidAndUnchecked">
        <el-alert show-icon type="warning">
          <template slot="title">
            {{ `家長已付清，請確認付款紀錄！` }}
          </template>
        </el-alert>
      </template>
    </template>
    <template v-if="isRoleParent()">
      <el-alert
        v-if="$store.getters['bill/billStatus'] === EnumBillStatuses.created"
        show-icon
        type="warning"
      >
        <template slot="title">
          <div v-if="hasSomeTransactionUnchecked">
            {{ `帳單未付款項還剩約 ${this.remainingCost}，款項確認中` }}
          </div>
          <div v-else>
            <span>{{ $t("bill.parent.warningAboutDidNotingPart1") }}</span>
            <a :href="`/bill/parents/problem/${$route.params.billId}`">
              {{ $t("bill.parent.submitProblemLink") }}
            </a>
            <span>{{ $t("bill.parent.warningAboutDidNotingPart2") }}</span>
          </div>
        </template>
      </el-alert>
      <el-alert
        v-else-if="
          $store.getters['bill/billStatus'] === EnumBillStatuses.problem ||
            $store.getters['bill/billStatus'] ===
              EnumBillStatuses.paidAndUnchecked
        "
        show-icon
        type="success"
      >
        <template slot="title">
          <span
            v-if="
              $store.getters['bill/billStatus'] === EnumBillStatuses.problem
            "
          >
            {{ $t("bill.parent.alreadySubmitedProblem") }}
          </span>
          <span v-else-if="isPaidAndUnchecked">
            {{ $t("bill.parent.alreadySubmitedPayInformation") }}
          </span>
        </template>
      </el-alert>
    </template>
    <template v-if="isPaid">
      <el-alert show-icon type="success">
        <template slot="title">
          {{
            `${$t("bill.detail.describePaidDateA")}${
              this.validTransaction.displayDate
            }${$t("bill.detail.describePaidDateB")}還沒有付清嗎？請點擊`
          }}
          <span
            class="billHasNotPayYet"
            v-if="isRoleAdmin()"
            @click="openTheSubmitUnpaidAmountDialog"
          >
            帳單未付清
          </span>
          ，填寫帳單未付金額。
        </template>
      </el-alert>
    </template>
    <template v-if="isUnpaidButChecked">
      <el-alert show-icon :type="isRoleAdmin() ? 'warning' : 'error'">
        <template slot="title">
          {{
            `此帳單還剩下 ${addCurrencySymbol(
              $store.getters["bill/billDifferencePrice"],
              $store.getters["bill/billDifferencePriceCurrency"]
            )} 尚未付款！`
          }}
        </template>
      </el-alert>
    </template>
    <h1 class="text-center mt-5 mb-3">
      <span>{{ pageTitle }}</span>
      <DeleteIconButton
        v-if="isRoleAdmin() && isPaid"
        :onDelete="deletePaidBill($route.params.billId)"
      />
      <span
        v-if="isRoleAdmin() && !isPaid"
        key="edit"
        class="action-icon"
        @click="resetBill"
      >
        <i class="fas fa-edit" />
      </span>
      <DeleteIconButton
        v-if="isRoleAdmin() && !isPaid"
        key="delete"
        :onDelete="deleteUnpaidBill($route.params.billId)"
      />
    </h1>
    <h2
      v-if="isRoleAdmin() && $store.getters['bill/billReceivableDate']"
      class="text-center"
    >
      繳費期限：{{ $store.getters["bill/billReceivableDate"] }}
    </h2>
    <el-row class="rowsGap">
      <el-table :data="[billProgress]" style="width: 100%">
        <el-table-column
          :label="$t('column.student')"
          prop="user_name"
          width="200"
        />
        <el-table-column>
          <template slot="header">
            <div style="text-align: center">
              {{ $t("column.billingProgress") }}
            </div>
          </template>
          <template v-if="billProgress" slot-scope="scope">
            <StepOfAdmin v-if="isRoleAdmin()" :scope="scope" />
            <StepOfParent v-else-if="isRoleParent()" :scope="scope" />
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <hr class="separate-line" />
    <el-row class="rowGap">
      <el-col>
        <div v-if="editTitle">
          <!-- {{ $t("bill.form.billName") }} -->
          <!-- <el-input
            style="width: 500px"
            :value="$store.getters['bill/billTitle']"
            @input="value => $store.dispatch('bill/setBillTitle', value)"
          />
          <el-input
            style="width: 500px"
            :value="$store.getters['bill/billNotes']"
            @input="value => $store.dispatch('bill/setBillNotes', value)"
          /> -->
          <el-row class="rowsGap mt-3" :gutter="20">
            <el-col :span="3">
              <span>{{ $t("bill.form.billName") }}</span>
            </el-col>
            <el-col :span="21">
              <el-input
                :value="$store.getters['bill/billTitle']"
                @input="
                  value => {
                    $store.dispatch('bill/setBillTitle', value);
                  }
                "
              />
            </el-col>
          </el-row>
          <el-row class="rowsGap mt-3" :gutter="20">
            <el-col :span="3">Notes:</el-col>
            <el-col :span="21">
              <el-input
                style="width: 100%;"
                type="textarea"
                :rows="2"
                :value="$store.getters['bill/billNotes']"
                @input="
                  value => {
                    $store.dispatch('bill/setBillNotes', value);
                  }
                "
              ></el-input>
            </el-col>
          </el-row>
          <div class="mt-3 text-center">
            <el-button type="primary" @click="updateBill">
              {{ $t("button.save") }}
            </el-button>
            <el-button
              type="danger"
              @click="
                () => {
                  editTitle = false;
                }
              "
            >
              {{ $t("button.cancel") }}
            </el-button>
          </div>
        </div>
        <span v-else>
          <el-button
            v-if="isRoleAdmin()"
            type="primary"
            @click="
              () => {
                editTitle = true;
              }
            "
          >
            <i class="fa fa-edit"></i>
            {{ $t("button.editBillName") }}和 Notes
          </el-button>
        </span>
        <h4 class="mt-2">Notes:</h4>
        <el-alert
          v-if="$store.getters['bill/billNotes']"
          type="warning"
          effect="dark"
          :closable="false"
        >
          {{  $store.getters['bill/billNotes'] }}
        </el-alert>
        <hr />
      </el-col>
    </el-row>
    <el-row class="rowGap">
      <el-col>
        <OrderItems
          :viewMode="true"
          :displaySelectedCheckbox="false"
          :action="isRoleAdmin()"
          :isPaid="isPaid"
          :billIsCreated="true"
          :orderItems="$store.getters['bill/orderItems']"
          :showTotalPrice="$store.getters['bill/billTotalPrice']"
        />
      </el-col>
    </el-row>
    <el-row v-if="$store.getters['bill/lessonLogs'].length > 0" class="rowGap">
      <el-col>
        <LessonLogs
          :viewMode="true"
          :displaySelectedCheckbox="false"
          :action="false"
          :billCurrency="$store.getters['bill/billCurrency']"
          :lessonLogs="$store.getters['bill/lessonLogs']"
        />
      </el-col>
    </el-row>
    <div v-if="$store.getters['bill/billStatus'] === EnumBillStatuses.problem">
      <hr class="separate-line" />
      <h2 class="text-center">
        {{ $t("bill.detail.billIssue") }}
      </h2>
      <el-row class="rowGap">
        <el-table :data="$store.getters['bill/problems']">
          <el-table-column :label="$t('column.item')">
            <template slot-scope="scope">
              <div :key="index" v-for="(item, index) in scope.row.items">
                {{
                  `${$t("bill.form.student")}${item.name} ${$t(
                    "bill.detail.items"
                  )}${item.title}`
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('bill.detail.responseContent')"
            prop="problem"
          />
          <el-table-column :label="$t('column.createTime')">
            <template slot-scope="scope">
              {{ instant.formatDate(scope.row.created_at) }}
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </div>
    <hr class="separate-line" />
    <h2 class="text-center">
      {{ $t("bill.detail.payment") }}
    </h2>
    <el-row class="rowGap">
      <h4>{{ $t("bill.detail.paymentType") }}</h4>
      <el-table :data="[$store.getters['bill/paymentInfo']]">
        <el-table-column
          :label="$t('column.paymentType')"
          prop="title"
          width="240px"
        />
        <el-table-column :label="$t('column.details')">
          <template slot-scope="scope">
            <div v-if="scope.row.account_name !== ''" class="paymentInfo">
              <div v-if="scope.row.account_name">
                {{ $t("bill.accountName") }}{{ scope.row.account_name }}
              </div>
              <div v-if="scope.row.bank">
                {{ $t("bill.bank") }}{{ scope.row.bank }}
              </div>
              <div v-if="scope.row.account_number">
                <span v-if="isMobilePayment">{{ $t("bill.account") }}</span>
                <span v-else>{{ $t("bill.accountNumber") }}</span
                >{{ scope.row.account_number }}
              </div>
              <div v-if="scope.row.account_number">
                <span v-if="isMobilePayment">{{ $t("bill.account") }}</span>
                <span v-else>{{ $t("bill.accountNumber") }}</span
                >{{ scope.row.account_number }}
              </div>
              <div v-if="scope.row.other_info">
                <el-image
                  class="mt-2"
                  style="width: 100px; "
                  :src="scope.row.other_info"
                  :preview-src-list="[scope.row.other_info]"
                >
                </el-image>
              </div>
            </div>
            <div v-else-if="isPayByBalance(scope.row.index)">
              <router-link
                :to="{
                  name: 'Balance',
                  query: { userId: $store.getters['bill/billParent'].id }
                }"
              >
                {{
                  `${addCurrencySymbol(
                    balanceTransaction.original_account_balance,
                    balanceTransaction.original_account_balance_currency
                  )} - ${addCurrencySymbol(
                    Number(balanceTransaction.original_account_balance) -
                      Number(balanceTransaction.account_balance),
                    balanceTransaction.original_account_balance_currency
                  )} = ${$t("bill.detail.restBalanceA")}${addCurrencySymbol(
                    balanceTransaction.account_balance,
                    balanceTransaction.account_balance_currency
                  )}${$t("bill.detail.restBalanceB")}`
                }}
              </router-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div v-if="isRoleAdmin()">
      <h3>
        帳單寄送紀錄
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="sendMailDialogVisible = true"
          circle
          size="small"
        >
        </el-button>
      </h3>
      <el-table :data="$store.getters['bill/emailToUsers']">
        <el-table-column label="寄送人">
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.created_user"
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.created_user.id }
              }"
            >
              {{ scope.row.created_user.name }}
            </router-link>
            <div v-else>
              未紀錄寄送人
            </div>
          </template>
        </el-table-column>
        <el-table-column label="日期">
          <template slot-scope="scope">
            {{ instant.formatDateTime(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="收件者">
          <template slot-scope="scope">
            <div
              :key="emailUser.email"
              v-for="emailUser in scope.row.emailUsers"
            >
              <span v-if="emailUser.id">
                {{ emailUser.email }}
                (<router-link
                  v-if="emailUser.id"
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: emailUser.id }
                  }"
                  >{{
                    userMethods.displayName(
                      emailUser.first_name,
                      emailUser.last_name
                    )
                  }}</router-link
                >)
              </span>
              <span v-else>
                {{ `${emailUser.email} (未知使用者)` }}
              </span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-row class="rowGap">
      <h3>
        {{ $t("bill.paidTransaction") || $t("transaction.transactions") }}
        <el-button type="primary" @click="enterSubmitTransaction"
          icon="el-icon-plus"
          circle
          size="small">
          <!-- {{ $t("transaction.form.inputPayTransaction") }} -->
        </el-button>
      </h3>
      <Transactions
        :title="$t('bill.paidTransaction')"
        :userWhoConfirmedTransaction="
          $store.getters['bill/userWhoConfirmedTransaction']
        "
        :transactions="$store.getters['bill/transactions']"
        @verifyTransaction="verifyTransaction"
        @loadTransactions="getBillAndConvertPrice"
      />
    </el-row>
    <el-row
      class="rowGap"
      v-if="$store.getters['bill/billStatus'] !== EnumBillStatuses.finished"
    >
      <!-- <div style="text-align: right">
        <el-button type="primary" @click="enterSubmitTransaction">
          {{ $t("transaction.form.inputPayTransaction") }}
        </el-button>
      </div> -->
      <MarkPaid
        v-if="openTransactionForm"
        :existBillId="$route.params.billId"
        @handleOk="
          () => {
            getBillAndConvertPrice();
            isAlreadyPaidDialogVisible = true;
          }
        "
      />
    </el-row>
  </div>
</template>

<script>
import billApi from "@/apis/bill";
import roleMixin from "@/mixins/role";
import moneyMixin from "@/mixins/money";
import Breadcrumb from "@/components/Breadcrumb";
import OrderItems from "@/components/bill/OrderItems.vue";
import LessonLogs from "@/components/bill/LessonLogs.vue";
import Transactions from "@/components/bill/Transactions.vue";
import DeleteIconButton from "@/components/button/DeleteIconButton";
import SendMail from "@/views/bill/SendMail.vue";
import MarkPaid from "@/views/bill/MarkPaid.vue";
import StepOfAdmin from "./StepOfAdmin";
import StepOfParent from "./StepOfParent";
import { InputMoney } from "@/components/input";
import { EnumBillStatuses } from "@/enums";
import { user, instant } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    OrderItems,
    LessonLogs,
    Transactions,
    MarkPaid,
    SendMail,
    StepOfAdmin,
    StepOfParent,
    InputMoney,
    DeleteIconButton
  },
  data() {
    return {
      editTitle: false,
      openTransactionForm: false,
      sendMailDialogVisible: false,
      isAlreadyPaidDialogVisible: false,
      submitUnpaidAmountDialog: false,
      tutoringPayments: []
    };
  },
  computed: {
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    EnumBillStatuses() {
      return EnumBillStatuses;
    },
    pageTitle() {
      return `${this.$store.getters["bill/billTitle"]} ${this.$t(
        "pageTitle.billView"
      )}`;
    },
    balanceTransaction() {
      return this.$store.getters["bill/balanceTransaction"];
    },
    isStudentAtThisBill() {
      if (this.$store.getters["bill/students"].length === 0) return true;

      const studentNames = this.$store.getters["bill/students"].map(
        ({ name }) => name
      );
      return studentNames.includes(this.$route.query.student);
    },
    isPaid() {
      return (
        this.$store.getters["bill/billStatus"] ===
        this.EnumBillStatuses.finished
      );
    },
    isPaidAndUnchecked() {
      return (
        this.$store.getters["bill/billStatus"] ===
        this.EnumBillStatuses.paidAndUnchecked
      );
    },
    isUnpaidButChecked() {
      return (
        this.$store.getters["bill/billStatus"] ===
        this.EnumBillStatuses.unpaidButChecked
      );
    },
    notPaidYet() {
      return this.$store.getters["bill/billDifferencePrice"] > 0;
    },
    hasSomeTransactionUnchecked() {
      return this.$store.getters["bill/transactions"].some(
        transaction => transaction.is_valid === 0
      );
    },
    remainingCost() {
      const uncheckedTransactions = this.$store.getters[
        "bill/transactions"
      ].filter(transaction => transaction.is_valid === 0);
      const uncheckedTransactionTotalCost = this.calculationPriceWithCurrency(
        uncheckedTransactions.map(({ originalPrice, originalCurrency }) => ({
          price: originalPrice,
          currency: originalCurrency
        })),
        this.$store.getters["bill/billCurrency"]
      );
      let remainingCost = 0;
      if (this.$store.getters["bill/billDifferencePrice"] === 0) {
        remainingCost =
          this.$store.getters["bill/billTotalPrice"] -
          uncheckedTransactionTotalCost;
      } else {
        remainingCost =
          this.$store.getters["bill/billDifferencePrice"] -
          uncheckedTransactionTotalCost;
      }
      return this.addCurrencySymbol(
        remainingCost,
        this.$store.getters["bill/billCurrency"]
      );
    },
    validTransaction() {
      return this.$store.getters["bill/transactions"].find(
        transaction => transaction.is_valid === 1
      );
    },
    isMobilePayment() {
      const mobilePaymentIndex = [5, 6, 7, 8];
      return mobilePaymentIndex.includes(
        this.$store.getters["bill/billPaymentIndex"]
      );
    },
    billProgress() {
      if (this.$store.getters["bill/billStatus"] === null) return;
      return {
        latestOrder: {
          id: this.$route.params.billId,
          status: this.$store.getters["bill/billStatus"],
          created_at: this.$store.getters["bill/createAt"],
          transactions: this.$store.getters["bill/transactions"],
          latest_transaction:  this.$store.getters["bill/transactions"]? this.$store.getters["bill/transactions"][
            this.$store.getters["bill/transactions"].length - 1
          ]: null,
          latest_problem: this.$store.getters["bill/problems"]? this.$store.getters["bill/problems"][
            this.$store.getters["bill/problems"].length - 1
          ]: null
        },
        user_name: this.$store.getters["bill/students"][0].name,
        userId: this.$store.getters["bill/students"][0].id,
        parents: [this.$store.getters["bill/billParent"]],
        markPaidAt: this.$store.getters["bill/markPaidAt"]
      };
    },
    allPaidPrice() {
      return this.calculationPriceWithCurrency(
        this.$store.getters["bill/transactions"]
          .filter(({ is_valid }) => is_valid)
          .map(({ originalPrice, originalCurrency }) => ({
            price: originalPrice,
            currency: originalCurrency
          })),
        this.$store.getters["bill/billCurrency"]
      );
    }
  },
  mixins: [roleMixin, moneyMixin],
  async created() {
    await this.initialExchangeRate();
    this.tutoringPayments = await billApi.getTutoringPayments();
    await this.getBillAndConvertPrice();
  },
  methods: {
    isPayByBalance(paymentIndex) {
      return paymentIndex === 10;
    },
    openTheSubmitUnpaidAmountDialog() {
      this.submitUnpaidAmountDialog = true;
    },
    updateDifferencePrice() {
      if (this.$store.getters["bill/billDifferencePrice"] >= 1) {
        this.$store.dispatch(
          "bill/updateOrderDifferencePrice",
          this.$route.params.billId
        );
        this.isAlreadyPaidDialogVisible = false;
        this.submitUnpaidAmountDialog = false;
        this.$message.success(this.$t("message.update_success"));
      } else {
        this.$message.error(
          "未付款金額應該要大於 0，如果帳單已付清請點選回上一頁完成帳單！"
        );
      }
    },
    async getBillAndConvertPrice() {
      await this.$store.dispatch("bill/getBill", this.$route.params.billId);
      this.$store.dispatch(
        "bill/setLessonLogs",
        this.addPriceByBillCurrency(
          this.$store.getters["bill/lessonLogs"],
          "fee",
          "feeCurrency"
        )
      );
      this.$store.dispatch(
        "bill/setOrderItems",
        this.addPriceByBillCurrency(
          this.$store.getters["bill/orderItems"],
          "price",
          "priceCurrency"
        )
      );
    },
    resetBill() {
      this.$router.push({
        name: "createBillWithOtherBill",
        params: { billId: this.$route.params.billId }
      });
    },
    deletePaidBill(billId) {
      return async () => {
        await this.$store.dispatch("bill/deletePaidBill", billId);
        this.$router.push({ name: "Bill" });
      };
    },
    deleteUnpaidBill(billId) {
      return async () => {
        await this.$store.dispatch("bill/deleteBill", billId);
        this.$router.push({ name: "Bill" });
      };
    },
    addPriceByBillCurrency(list, priceColumn, priceCurrencyColumn) {
      return list.map(item => ({
        ...item,
        [`${priceColumn}ByCurrency`]: this.convertPriceByCurrency(
          item[priceColumn],
          item[priceCurrencyColumn],
          this.$store.getters["bill/billCurrency"]
        )
      }));
    },
    async sendMail(users, customContent, lang) {
      try {
        await this.$store.dispatch("bill/sendBill", {
          billId: this.$route.params.billId,
          usersMail: users.map(({ mail }) => mail),
          customContent,
          lang
        });
        this.$store.dispatch("bill/getBill", this.$route.params.billId);
        this.$message.success("帳單寄送成功！");
        this.sendMailDialogVisible = false;
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async markPaid() {
      await this.$store.dispatch("bill/markPaid", {
        id: this.$route.params.billId,
        order_items: this.$store.getters["bill/orderItems"],
        lesson_logs: this.$store.getters["bill/lessonLogs"]
      });
      this.getBillAndConvertPrice();
    },
    async verifyTransaction(transactionId) {
      await this.$store.dispatch("bill/verifyTransaction", transactionId);
      this.isAlreadyPaidDialogVisible = true;
      await this.getBillAndConvertPrice();
    },
    enterSubmitTransaction() {
      if (this.isRoleAdmin()) {
        this.openTransactionForm = true;
      } else if (this.isRoleParent()) {
        this.$router.push({
          name: "parentsSubmitTransaction",
          params: { billId: this.$route.params.billId }
        });
      }
    },
    async updateBill() {
      await this.$store.dispatch("bill/updateBill", this.$route.params.billId);
      this.editTitle = false;
    }
  }
};
</script>

<style scoped>
.paymentInfo {
  margin: 4px;
  padding: 9.5px;
  font-size: 14px;
  color: #333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.rowGap {
  margin: 20px 0px;
}

::v-deep .is-icon {
  width: 120px;
}

.el-table__row:hover .el-steps ::v-deep .is-icon {
  background: rgb(245, 247, 250);
}

.billHasNotPayYet {
  cursor: pointer;
  color: red;
}

.billHasNotPayYet:hover {
  text-decoration: underline;
}
</style>
