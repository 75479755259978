<template>
  <el-steps :active="transferStep(scope.row.latestOrder.status)">
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <el-tooltip
          placement="top"
          v-if="scope.row.latestOrder.id"
          :content="instant.formatDate(scope.row.latestOrder.created_at)"
          popper-class="tooltipColor"
        >
          <a>{{ $t("bill.process.statementCreated") }}</a>
        </el-tooltip>
      </template>
    </el-step>
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <el-tooltip
          placement="top"
          v-if="isSubmitedTransation || isPaid || hasSomeTransactionUnchecked"
          :content="
            instant.formatDate((scope.row.latestOrder.latest_transaction || {}).created_at)
          "
          popper-class="tooltipColor"
        >
          <a>{{ $t("bill.process.statementPaid") }}</a>
        </el-tooltip>
        <el-tooltip
          placement="top"
          v-else-if="isSubmitedProblem"
          :content="instant.formatDate((scope.row.latestOrder.latest_problem || {}).created_at)"
          popper-class="tooltipColor"
        >
          <span class="waiting">
            {{ $t("bill.process.statementIssue") }}
          </span>
        </el-tooltip>
        <span v-else :class="scope.row.latestOrder.id ? 'wrong' : ''">
          {{ $t("bill.process.paymentPending") }}
        </span>
      </template>
    </el-step>
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <span
          v-if="!isPaid || hasSomeTransactionUnchecked"
          :class="
            (isSubmitedTransation || hasSomeTransactionUnchecked) ? 'waiting' : ''
          "
        >
          {{ $t("bill.process.confirmPending") }}
        </span>
        <el-tooltip
           v-else
          placement="top"
          :content="instant.formatDate(scope.row.markPaidAt)"
          popper-class="tooltipColor"
        >
          <span>
            {{ $t("bill.process.statementPaid") }}
          </span>
        </el-tooltip>
      </template>
    </el-step>
  </el-steps>
</template>

<script>
import { instant } from "@ivy-way/material";
import { EnumBillStatuses } from "@/enums";

export default {
  props: {
    scope: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    instant() {
      return instant;
    },
    EnumBillStatuses() {
      return EnumBillStatuses;
    },
    isSubmitedTransation() {
      return this.scope.row.latestOrder.status === this.EnumBillStatuses.paidAndUnchecked;
    },
    isSubmitedProblem() {
      return this.scope.row.latestOrder.status === this.EnumBillStatuses.problem;
    },
    isPaid() {
      return this.scope.row.latestOrder.status === this.EnumBillStatuses.finished;
    },
    hasSomeTransactionUnchecked() {
      return (this.scope.row.latestOrder?.transactions || []).some(
        transaction => transaction.is_valid === 0
      );
    }
  },
  methods: {
    transferStep(step) {
      switch (step) {
        case this.EnumBillStatuses.created:
          if (this.hasSomeTransactionUnchecked) {
            return 2;
          } else {
            return 1;
          }
        case this.EnumBillStatuses.problem:
          return 1;
        case this.EnumBillStatuses.paidAndUnchecked:
        case this.EnumBillStatuses.unpaidButChecked:
          return 2;
        case this.EnumBillStatuses.finished:
          return 3;
        default:
          return 0;
      }
    },
    isWrong(status) {
      if (status === this.EnumBillStatuses.problem) return "wrong";
    }
  }
};
</script>

<style scoped>
::v-deep .el-step__description {
  padding-right: 0px;
}

.wrong ::v-deep .is-icon {
  color: red;
}

.wrong {
  color: red;
}

.waiting {
  color: orange;
}
</style>
