<template>
  <el-steps :active="transferStep(scope.row.latestOrder.status)">
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <el-tooltip
          placement="top"
          v-if="scope.row.latestOrder.id"
          :content="instant.formatDate(scope.row.latestOrder.created_at)"
          popper-class="tooltipColor"
        >
          <a>{{ $t("bill.process.statementCreated") }}</a>
        </el-tooltip>
        <a
          v-else-if="scope.row.parent_user_name === 'Unknown'"
          :href="`/user/${scope.row.students[0].userId}/true`"
        >
          <span class="wrong">
            <i class="el-icon-connection" />{{
              $t("bill.process.linkParent")
            }}</span
          >
        </a>
        <a v-else :href="`bill/create/student/${scope.row.students[0].userId}`">
          <span :class="isBillThisMonth ? 'default' : 'wrong'">
            + {{ $t("bill.process.createBill") }}
          </span>
        </a>
      </template>
    </el-step>
    <el-step icon="el-icon-s-order">
      <template slot="icon">
        <el-tooltip
          placement="top"
          v-if="isSubmittedTransaction || isPaid"
          :content="
            instant.formatDate(scope.row.latestOrder.latest_transaction.created_at)
          "
          popper-class="tooltipColor"
        >
          <a>{{ $t("bill.process.statementConfirmed") }}</a>
        </el-tooltip>
        <el-tooltip
          placement="top"
          v-else-if="isSubmittedProblem"
          :content="instant.formatDate(scope.row.latestOrder.latest_problem.created_at)"
          popper-class="tooltipWrong"
        >
          <span class="wrong">
            {{ $t("bill.process.statementIssue") }}
          </span>
        </el-tooltip>
        <span v-else :class="getPaymentPendingClass(scope.row)">
          {{ $t("bill.process.paymentPending") }}
        </span>
      </template>
    </el-step>
    <el-step :class="isWrong(scope.row.status)" icon="el-icon-s-order">
      <template slot="icon">
        <span
          v-if="!isPaid || hasSomeTransactionUnchecked"
          :class="isSubmittedTransaction ? 'wrong' : ''"
        >
          {{ $t("bill.process.confirmPending") }}
        </span>
        <el-tooltip
           v-else
          placement="top"
          :content="instant.formatDate(scope.row.markPaidAt)"
          popper-class="tooltipColor"
        >
          <span>
            {{ $t("bill.process.statementPaid") }}
          </span>
        </el-tooltip>
      </template>
    </el-step>
  </el-steps>
</template>

<script>
import moment from "moment";
import { EnumBillStatuses } from "@/enums";
import { instant } from "@ivy-way/material";

export default {
  props: {
    scope: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    instant() {
      return instant;
    },
    EnumBillStatuses() {
      return EnumBillStatuses;
    },
    isSubmittedTransaction() {
      return this.scope.row.latestOrder.status === this.EnumBillStatuses.paidAndUnchecked ||
        this.hasSomeTransactionUnchecked;
    },
    isSubmittedProblem() {
      return this.scope.row.latestOrder.status === this.EnumBillStatuses.problem;
    },
    isPaid() {
      return this.scope.row.latestOrder.status === this.EnumBillStatuses.finished;
    },
    hasSomeTransactionUnchecked() {
      return (this.scope.row.latestOrder?.transactions || []).some(
        transaction => transaction.is_valid === 0
      );
    },
    isBillThisMonth() {
      if (this.scope.row.oldestUnpaidItemDate) {
        const now = moment();
        return (
          now.diff(moment(this.scope.row.oldestUnpaidItemDate), "months") <= 1
        );
      } else {
        return true;
      }
    }
  },
  methods: {
    transferStep(step) {
      switch (step) {
        case this.EnumBillStatuses.created:
          if (this.hasSomeTransactionUnchecked) {
            return 2;
          } else {
            return 1;
          }
        case this.EnumBillStatuses.problem:
          return 1;
        case this.EnumBillStatuses.paidAndUnchecked:
        case this.EnumBillStatuses.unpaidButChecked:
          return 2;
        case this.EnumBillStatuses.finished:
          return 3;
        default:
          return 0;
      }
    },
    getPaymentPendingClass(unpaidItem) {
      if (unpaidItem.latestOrder.id) {
        if (moment(unpaidItem.latestOrder.receivable_date).isBefore(moment())) {
          return "wrong";
        } else {
          return "waiting";
        }
      } else {
        return "";
      }
    },
    isWrong(status) {
      if (status === this.EnumBillStatuses.problem) return "wrong";
    }
  }
};
</script>

<style scoped>
::v-deep .el-step__description {
  padding-right: 0px;
}

.wrong ::v-deep .is-icon {
  color: red;
}

.wrong {
  color: red;
}

.default {
  color: #c0c4cc;
}

.waiting {
  color: orange;
}
</style>
